export default (
  element: Element,
  componentFileName: string,
  requireElement = false,
  ...args: unknown[]
) => {
  const target = element;
  const entryCallback = (
    elements: IntersectionObserverEntry[],
    observerRef: IntersectionObserver,
  ) => {
    elements.map(async (entry) => {
      if (entry.isIntersecting) {
        observerRef.unobserve(target);

        // Path to your modules
        import(/* webpackChunkName: "[request]" */`../${componentFileName}`)
          .then(({ default: Component }) => {
            if (element) {
              const component = requireElement ? new Component(element, ...args) : new Component();

              // Run your component/Class
              return component;
            }
            return null;
          })
          .catch((err) => {
            throw new Error(`${err} , ${componentFileName} doesn't exists`);
          });
      } else {
        // Logic for element if it's not visible
        entry.target.classList.remove('visible');
      }
    });
  };

  const componentObserver = new IntersectionObserver(entryCallback);

  return componentObserver;
};
