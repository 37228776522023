const TABLET = 768;
const DESKTOP = 1024;

let windowWidth: number = null;

const setWidth = (): null => {
  const width = window.innerWidth
  || document.documentElement.clientWidth
  || document.body.clientWidth;
  windowWidth = width;
  return null;
};

export const isMobile = (): boolean => {
  setWidth();
  return windowWidth < TABLET;
};
export const isTablet = (): boolean => {
  setWidth();
  return windowWidth >= TABLET;
};
export const isDesktop = (): boolean => {
  setWidth();
  return windowWidth >= DESKTOP;
};

export const matchDesktop = () => window.matchMedia('(min-width: 1024px)');

export const matchTablet = () => window.matchMedia('(max-width: 1023px)');
