import observer from '../components/utility/observer';

export default (): string => {
  const carouselElements = document.querySelectorAll('.js-carousel');
  if (carouselElements.length) {
    carouselElements.forEach(async (element) => {
      observer(element, 'carousel/carousel', true).observe(element);
    });
  }
  return null;
};
