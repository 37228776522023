const offset = 250;
const duration = 250;

export default () => {
  const backToTopElement = document.getElementById('js-back-to-top') as HTMLAnchorElement;

  if (backToTopElement) {
    /**
   * Show hide button based on scroll offset
   */
    const backToTopHandler = () => {
      if (window.pageYOffset > offset) {
        setTimeout(() => {
          backToTopElement.style.bottom = '0';
        }, duration);
      } else {
        setTimeout(() => {
          backToTopElement.style.bottom = '-50px';
        }, duration);
      }
    };
    window.addEventListener('scroll', backToTopHandler);
    backToTopElement.addEventListener('click', (e) => {
      e.preventDefault();
      import(/* webpackChunkName: "back-to-top" */ '../components/back-to-top')
        .then(({ default: BackToTop }) => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
          return new BackToTop(backToTopElement);
        })
        .catch((error) => `Back to top : ${error}`);
    });
  }
};
