import VideoPlayer from 'tridion.web.video/src/app/VideoPlayer';
import {
  ICON_PAUSE, ICON_PLAY, OVERLAY_CLASS_SHOW, TOGGLE_WRAPPER,
} from '../utility/constants';

const videoPauseLogic = (overlay: Element | null, playButtons: Element[]) => {
  if (overlay) {
    overlay.closest(TOGGLE_WRAPPER).classList.remove(OVERLAY_CLASS_SHOW);
  }
  Array.from(playButtons).forEach((button) => {
    const icon = button?.querySelector('i');
    icon?.classList.replace(ICON_PAUSE, ICON_PLAY);
  });
};

export default class VideoPlayerAdapter extends VideoPlayer {
  getVideoDivId = () => (this.element as HTMLDivElement).id;

  getFullscreen = () => this.jwplayer.getFullscreen();

  setFullscreen = (state: boolean) => this.jwplayer.setFullscreen(state);

  /**
   * Show overlay on video complete
   */
  onComplete = (overlay: Element, playButtons: Element[]) => {
    this.jwplayer.on('complete', () => videoPauseLogic(overlay, playButtons));
  };

  onBeforePlay = (cb: () => void) => this.jwplayer.on('beforePlay', cb);

  onPause = (overlay: Element, playButtons: Element[]) => this.jwplayer.on('pause', () => videoPauseLogic(overlay, playButtons));

  onPlay = (overlay: Element, playButtons: Element[]) => this.jwplayer.on('play', () => {
    if (overlay && !overlay.classList.contains(OVERLAY_CLASS_SHOW)) {
      overlay.closest(TOGGLE_WRAPPER).classList.add(OVERLAY_CLASS_SHOW);
    }
    Array.from(playButtons).forEach((button) => {
      button?.querySelector('.i')?.classList.replace(ICON_PLAY, ICON_PAUSE);
    });
  });
}
