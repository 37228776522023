// Youtube Iframe API
export const VIDEO_PLAY = 'YOUTUBE_VIDEO_PLAYS';
export const VIDEO_STOP = 'YOUTUBE_VIDEO_STOP';
export const VIDEO_FINISH = 'YOUTUBE_VIDEO_FINISH';

export const PLATFORM_VIDEO_PLAY = 'PLATFROM_VIDEO_PLAY';

// JWPLayer State
export const VIDEO_STATE_PLAYING = 'playing';

// Triggers
export const BUTTON_CLASS_PLAY = '.js-play-video';
export const BUTTON_CLASS_PAUSE = '.js-pause-video';

// Custom event
export const VIDEO_PLAY_EVENT = 'videoPlays';

// Player icons
export const ICON_PLAY = 'i-play';
export const ICON_PAUSE = 'i-pause';

// Modals & Overlay
export const PROMOBOX = 'js-promobox-ma';
export const PROMOBOX_BUTTON = 'js-promobox-ma-button';
export const PROMOBOX_WRAPPER = 'js-promobox-ma-wrapper';
export const IS_OPEN = 'is-open';
export const TOGGLE_PROMOBOX_EVENT = 'toggle-promobox-modal';
export const OVERLAY_CLASS_SHOW = 'active';
export const TOGGLE_WRAPPER = '.js-toggle-wrap';
export const MEDIA_OVERLAY = '.js-media-overlay';

// Search
export const NUMBER_OF_CHARACTERS_TO_SEARCH = 2;
