/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Pub Sub pattern for component communication
 */
interface Subscribers {
  [key: string]: Array<(data: { [prop: string]: any }) => void>;
}
const subscribers: Subscribers = {};

export default {
  /**
   * Send data to all subscribers for this event
   * @param {string} event - event name
   * @param {object} data - holder for data
   */
  publish(event: string, data: { [prop: string]: any }) {
    if (!subscribers[event]) {
      return;
    }

    subscribers[event].forEach((subscriberCallback) => subscriberCallback(data));
  },

  /**
   * Subscribe for event and wait for incoming data
   */
  subscribe(event: string, callback:(data: { [prop: string]: any }) => void) {
    let index = 0;

    if (!subscribers[event]) {
      subscribers[event] = [];
    }

    index = subscribers[event].push(callback) - 1;

    return {
      unsubscribe() {
        subscribers[event].splice(index, 1);
      },
    };
  },
};
