/**
 * Generic Javascript Bootstraping
 */
import appModule from './modules/app-module';
import backToTopModule from './modules/back-to-top-module';
import infographicModule from './modules/infographic-module';
import infographicFlowModule from './modules/infographic-flow-module';
import ctaAsLinkModule from './modules/cta-as-link-module';
import selectModule from './modules/select-module';
import stockModule from './modules/stock-module';
import tableGraphModule from './modules/table-graph-module';
import videosModule from './modules/videos-module';
import animationsModule from './modules/animations-module';
import brandListModule from './modules/brand-list-module';
import formValidationModule from './modules/form-validation-module';
import formAnimationModule from './modules/form-animation-module';
import carouselModule from './modules/carousel-module';
import topSearchModule from './modules/top-search-module';
import lazyLoadScriptsModule from './modules/lazy-load-scripts-module';
import navigationModule from './modules/navigation-module';
import modalModule from './modules/modal-module';
import tabModule from './modules/tab-module';
import mailToModule from './modules/mail-to-module';
import toggleModule from './modules/toggle-module';
import oneTrustCookieYoutubeModule from './modules/oneTrust-cookie-youtube-module';
import formEventsModule from './modules/form-events-module';
import isIOS from './components/utility/detect-device';

// eslint-disable-next-line @typescript-eslint/no-require-imports
require('matchmedia-polyfill');
// eslint-disable-next-line @typescript-eslint/no-require-imports
require('matchmedia-polyfill/matchMedia.addListener');

appModule();

window.Essity = {};

const loadModules = () => {
  backToTopModule();
  infographicFlowModule();
  ctaAsLinkModule();
  navigationModule();
  modalModule();
  tabModule();
  topSearchModule();
  mailToModule();
  stockModule();
  infographicModule();
  tableGraphModule();
  videosModule();
  animationsModule();
  brandListModule();
  formEventsModule();
  formValidationModule();
  formAnimationModule();
  carouselModule();
  oneTrustCookieYoutubeModule();
};

if (!isIOS) {
  window.onload = loadModules;
}

document.onreadystatechange = () => {
  if (document.readyState === 'complete') {
    lazyLoadScriptsModule();
    selectModule();
    toggleModule();

    if (isIOS) {
      loadModules();
    }
  }
};
