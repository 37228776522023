import { BUTTON_CLASS_PAUSE, BUTTON_CLASS_PLAY } from '../components/utility/constants';
import observerMultiple from '../components/utility/observer-multiple';
import VideoPlayerAdapter from '../components/video/video-player-adapter';

export default (): unknown => {
  const videoElements = document.querySelectorAll('.video-player');
  const playTriggers = document.querySelectorAll(BUTTON_CLASS_PLAY);
  const pauseTriggers = document.querySelectorAll(BUTTON_CLASS_PAUSE);
  if (videoElements.length) {
    const args = Array.from(videoElements).reduce((acc, current) => {
      const { id } = current;
      const playButtons = Array.from(playTriggers).filter(
        (button) => (button as HTMLButtonElement).dataset.videoId === id,
      );
      const pauseButtons = Array.from(pauseTriggers).filter(
        (button) => (button as HTMLButtonElement).dataset.videoId === id,
      );
      const options = VideoPlayerAdapter.getVideoData((current as HTMLDivElement));
      return [...acc, [options, playButtons, pauseButtons]];
    }, []);

    if (args.length) {
      observerMultiple(videoElements, 'video/video-player', true, args);
    }
  }
  return null;
};
