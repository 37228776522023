import { isMobile } from '../components/media-query';
import observer from '../components/utility/observer';

export default ():string => {
  const firstTableGraphElement = document.querySelector('.js-table-graph');
  if (firstTableGraphElement && !isMobile()) {
    observer(firstTableGraphElement, 'table-graph').observe(firstTableGraphElement);
  }
  return null;
};
