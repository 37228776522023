import isIOS from './detect-device';

/**
 * Change width of container
 * ESS-2443
 * @param element - DIV element with container class
 * @param value - value in px
 */
const changeContainerWidth = (element: Element, value: string) => {
  const htmlelement = element as HTMLElement;
  htmlelement.style.paddingLeft = value;
  htmlelement.style.paddingRight = value;
};

export default (
  elements: NodeListOf<Element>,
  componentFileName: string,
  requireElement: boolean,
  argsArray: unknown[][],
) => {
  const entryCallback = (
    index: number,
  ) => (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        // Dynamically import the component module
        import(/* webpackChunkName: "[request]" */`../${componentFileName}`)
          .then(({ default: Component }) => {
            const targetElement = entry.target;
            if (targetElement) {
              const componentArgs = argsArray[index];
              const component = requireElement
                ? new Component(targetElement, ...componentArgs)
                : new Component();

              // Run your component/Class
              return component;
            }
            return null;
          })
          .catch((err) => {
            // Handle errors if the component module cannot be imported
            throw new Error(`${err} , ${componentFileName} doesn't exist`);
          });
      } else {
        // Logic for the element if it's not visible
        entry.target.classList.remove('visible');
      }
    });
  };

  // Create an IntersectionObserver for each element
  const observers = Array.from(elements).map((element, index) => {
    const observerCallback = entryCallback(index);
    const containers = document.querySelectorAll('.container');

    if (isIOS) {
      containers.forEach((containerElement) => changeContainerWidth(containerElement, '0px'));
    }

    const observer = new IntersectionObserver(observerCallback);
    observer.observe(element);
    if (isIOS) {
      setTimeout(() => {
        containers.forEach((containerElement) => changeContainerWidth(containerElement, ''));
      }, 10);
    }
    return observer;
  });

  // Return the array of IntersectionObservers
  return observers;
};
