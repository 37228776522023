export default (): string => {
  const mailToTriggers = document.querySelectorAll('[data-mail][data-mail-client]') as NodeListOf<HTMLAnchorElement>;
  if (mailToTriggers.length) {
    mailToTriggers.forEach((mailToTrigger) => {
      mailToTrigger.addEventListener('click', async (event: Event) => {
        if (!(event.target as HTMLAnchorElement).href.includes('mailto')) {
          event.preventDefault();
        }
        const handler = await import(/* webpackChunkName: "mail-to" */ '../components/mailto');
        const mailTo = handler.default;
        mailTo(event);
      });
    });
  }
  return null;
};
