import observer from '../components/utility/observer';

export default ():string => {
  const formElements = document.querySelectorAll('[data-form="validator"]');
  if (formElements.length) {
    formElements.forEach((formElement) => {
      observer(formElement, 'forms/form-validation', true).observe(formElement);
    });
  }
  return null;
};
