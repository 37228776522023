import { isMobile } from '../components/media-query';

export default () => {
  if (document.querySelectorAll('[data-aos]').length && !isMobile()) {
    return import(/* webpackChunkName: "animationAOS" */ '../components/animations')
      .then(({ default: Animations }) => new Animations())
      .catch((error) => `animationAOS module : ${error}`);
  }
  return null;
};
