export default () => {
  const topSearchButton = document.getElementById('top-search-button');
  const navbarSearchButton = document.getElementById('navbar-search-button');
  if (topSearchButton) {
    [topSearchButton, navbarSearchButton].forEach((element) => {
      element.addEventListener('click', (e) => {
        e.preventDefault();
        import('../components/top-search/top-search')
          .then(({ default: TopSearch }) => new TopSearch())
          .catch((err) => `Top search module : ${err}`);
      });
    });
  }
};
