import pubSub from '../components/utility/pub-sub';

export const MODAL_INIT = 'modal-init';

const relocateModals = () => {
  const modalElements = document.querySelectorAll('.modal');

  modalElements.forEach((modal) => {
    document.body.insertAdjacentElement('beforeend', modal);
  });
};

export default (): string => {
  const modalTriggers = document.querySelectorAll('.js-modal') as NodeListOf<HTMLButtonElement>;
  if (modalTriggers.length) {
    relocateModals();
    modalTriggers.forEach((modalTrigger) => {
      modalTrigger.addEventListener('click', async (event: Event) => {
        const modal = await import(/* webpackChunkName: "modal" */ '../components/modal')
          .then(({ default: Modal }) => new Modal(modalTriggers, event))
          .catch((error) => `Modal : ${error}`);
        return modal;
      });
    });
  }
  pubSub.subscribe('modal-init', async () => {
    const modal = await import(/* webpackChunkName: "modal" */ '../components/modal')
      .then(({ default: Modal }) => new Modal(null, null))
      .catch((error) => `Modal : ${error}`);
    return modal;
  });
  return null;
};
